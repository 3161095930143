//
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>
    <div class="history_body" v-show="$store.state.islogin">
      <!-- 左侧 -->
      <Leftside></Leftside>
      <transition name="RightToleft" v-if="$store.state.islogin">
        <!-- 右侧主题 -->
        <div class="his_r_box" v-show="enter">
          <div class="history_body_right">
            <div class="his_r_title"><span>意见反馈</span></div>
            <!-- 主体 -->
            <div class="his_r_body">
              <div class="opi_body">
                <select v-model="opi.category_id" class="opi_sel">
                  <option
                    :value="item.id"
                    v-for="(item, index) in feedback_category_list"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div class="opi_body2">
                  <textarea
                    maxlength="255"
                    v-model="opi.content"
                    placeholder="请描述您遇到的问题"
                  ></textarea>
                </div>
                <div class="opi_body3">
                  <a @click="clia" class="opi_a" :class="{ cliloading: loadCli }">
                    <span v-show="!loadCli">提交</span>
                    <div class="dots-6" v-show="loadCli"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
export default {
  components: {
    Top,
    Leftside,
  },
  name: 'Opinion',
  data() {
    return {
      loadCli: false,
      enter: false,
      opi: {
        category_id: 1,
        content: '',
      },
      feedback_category_list: [],
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    fetch;
    vuex;

    if (that.YS.ismob()) {
      //移动端地址
      window.location.href =
        window.g.ub_index == 2
          ? window.g.MOBILE_URL_BO
          : window.g.ub_index == 3
          ? window.g.MOBILE_URL_U
          : window.g.MOBILE_URL + '/mcenter.html?go=seekaPiece';
    }
  },
  mounted() {
    that.enter = true;
    that.YS.closeallWin(); //关闭所有弹窗
    that.YS.actlist(that.$store.state.leftListcss, 4, 'history_lc_list_act'); //添加默认选中样式
    that.YS.iconAct(that.$store.state.leftComponent, 4); //添加默认选中样式
    that.feedbackcategory(); //获取反馈类型
  },
  methods: {
    //点击提交反馈按钮
    clia() {
      if (that.loadCli) return false;
      that.loadCli = true;
      if (that.opi.content.length >= 5) {
        that.feedbackpost();
      } else {
        that.loadCli = false;
        that.$refs.top.usepubilDialog({
          title: '请至少输入5个字！',
          type: '',
          code: false,
        });
      }
    },
    //获取反馈类型
    feedbackcategory() {
      fetch
        .api_feedbackcategory({})
        .then((data) => {
          if (data && data.feedback_category_list && data.feedback_category_list[0]) {
            that.feedback_category_list = data.feedback_category_list;
            that.opi.category_id = data.feedback_category_list[0].id;
            if (that.$route.query.symbol) {
              that.feedback_category_list.forEach((e) => {
                if (e.symbol) {
                  if (e.symbol == that.$route.query.symbol) {
                    that.opi.category_id = e.id;
                  }
                }
              });
            }
          }
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
    //提交反馈
    feedbackpost() {
      fetch
        .api_feedbackpost(that.opi)
        .then((data) => {
          data;
          that.$refs.top.usepubilDialog({ title: '反馈成功', type: '', code: true });
        })
        .catch((error) => {
          that.$refs.top.usepubilDialog({ title: error.message, type: '', code: false });
        })
        .finally(() => {
          that.loadCli = false;
        });
    },
  },
};
</script>
<style lang="scss" scope></style>
